export const mainMenu = [{
  label: 'Sobre nosotros',
  anchor: '#alliance'
},{
  label: 'Funcionalidad',
  anchor: '#modulos'
},{
  label: 'Planes',
  anchor: '#planes'
},{
  label: 'Contadores',
  anchor: '#contadores'
},{
  label: 'Quédate en casa',
  anchor: '#quedateencasa'
},{
  label: 'Contáctanos',
  anchor: '#contacto'
}];
