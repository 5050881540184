<template>
  <section id="quedateencasa" class="py-5 position-relative bg-white border-top">
    <div class="container">
      <div
        class="row justify-content-center align-items-center"
      >
        <div class="col-12 col-lg-6 pb-4 pb-md-0">
          <h1 class="text-dark display-3">
            <strong>Quédate en casa con Alliance</strong>
          </h1>
          <p class="lead mt-4 pt-lg-3">
           Que tus operaciones no se detengan, tu equipo puede trabajar desde casa, el software administrativo y de control Gerencial que le permitirá a todo tu equipo conectarse desde cualquier parte del mundo.
          </p>
          <p>
            Podemos ayudarte a que te prepares a este nuevo cambio, ofreciéndote
            una herramienta que te permitirá acceso global.
          </p>
          <a href="">Regístrese para obtener acceso anticipado <i class="ri-external-link-line"></i></a>
        </div>
        <div class="col-12 col-lg-6 pt-4 pt-md-0">
          <img :src="require(`@/assets/stay.png`)" class="img-fluid" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "StayHome",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
