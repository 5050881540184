import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import * as ScrollMagic from "scrollmagic";

import { gsap } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);


const app = createApp(App).use(router);
app.config.globalProperties.$scrollMagic = new ScrollMagic.Controller();
app.mount("#app");
