<template>
  <section id="planes" class="py-5 position-relative bg-white">
    <div class="container">
      <div
        class="row no-gutters pb-4 justify-content-center text-center mx-auto text-center"
      >
        <div class="col-12 col-lg-7">
          <h1 class="text-dark">
            Planes
          </h1>
          <p class="lead mt-3">
            Elige uno de nuestros planes y haz crecer tu negocio con Alliance
          </p>
        </div>
      </div>
      <div class="row text-center justify-content-center">
        <!-- <div class="col-12 col-md-3 pb-4 px-5 pb-md-0 px-md-2">
          <div class="card pricing mb-4 shadow-sm" style="height: 100%">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Inicio</h4>
            </div>
            <div class="card-body d-flex flex-column align-items-stretch">
              <h1 class="card-title pricing-card-title">
                $5 <small class="text-muted">/ mes</small>
              </h1>
              <div class="f-auto">
                <ul class="list-unstyled mt-3 mb-4">
                  <li>1 usuario</li>
                  <li>2 Bodegas</li>
                  <li>20 facturas</li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <a
                class="w-100 btn btn-small btn-outline-primary"
                :href="`mailto:${email}?subject=Plan Inicio`"
                >{{ "Contáctanos" }}</a
              >
            </div>
          </div>
        </div> -->
        <div class="col-12 col-md-3 pb-4 px-5 pb-md-0 px-md-2">
          <div class="card pricing mb-4 shadow-sm" style="height: 100%">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Basico</h4>
            </div>
            <div class="card-body d-flex flex-column align-items-stretch">
              <h1 class="card-title pricing-card-title">
                $15 <small class="text-muted">/ mes</small>
              </h1>
              <div class="f-auto">
                <ul class="list-unstyled mt-3 mb-4">
                  <li>1 usuarios</li>
                  <li>1 Bodegas/Almacenes</li>
                  <li>POS</li>
                  <li>100 facturas/Entradas en contabilidad</li>
                  <li>Multimoneda</li> 
                </ul>
              </div>
              <a
                class="w-100 btn btn-small btn-outline-primary"
                :href="`mailto:${email}?subject=Plan Básico`"
                >{{ "Contáctanos" }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 pb-4 px-5 pb-md-0 px-md-2">
          <div class="card pricing mb-4 shadow-sm" style="height: 100%">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Intermedio</h4>
            </div>
            <div class="card-body d-flex flex-column align-items-stretch">
              <h1 class="card-title bg-white">
                $30 <small class="text-muted">/ mes</small>
              </h1>
              <div class="f-auto">
                <ul class="list-unstyled mt-3 mb-4">
                  <li>3 usuarios</li>
                  <li>2 Bodegas/Almacenes</li>
                  <li>POS</li>
                  <li>300 facturas/Entradas en contabilidad</li>
                  <li>Multimoneda</li>
                </ul>
              </div>
              <a
                class="w-100 btn btn-small btn-outline-primary"
                :href="`mailto:${email}?subject=Plan Profesional`"
                >{{ "Contáctanos" }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 pb-4 px-5 pb-md-0 px-md-3">
          <div
            class="card pricing mb-4 shadow-sm bg-dark text-white dark"
            style="height: 100%"
          >
            <div class="card-header">
              <h4 class="my-0 fw-normal">
                Avanzado <i class="ri-star-fill text-warning"></i>
              </h4>
            </div>
            <div class="card-body d-flex flex-column align-items-stretch">
              <h1 class="card-title pricing-card-title">
                $45 <small class="text-muted">/ mes</small>
              </h1>
              <div class="f-auto">
                <ul class="list-unstyled mt-3 mb-4">
                  <li>3 usuarios</li>
                  <li>5 Bodegas/Almacenes</li>
                  <li>POS</li>
                  <li>2500 facturas/Entradas en contabilidad</li>
                  <li>Multimoneda</li>
                </ul>
              </div>
              <a
                class="w-100 btn btn-small btn-primary"
                :href="`mailto:${email}?subject=Plan Avanzado`"
                >{{ "Contáctanos" }}</a
              >
            </div>
            
          </div>
        </div>
        <div class="col-12 col-md-3 pb-4 px-5 pb-md-0 px-md-3">
          <div
            class="card pricing mb-4 shadow-sm "
            style="height: 100%"
          >
            <div class="card-header">
              <h4 class="my-0 fw-normal">
                Ultra 
              </h4>
            </div>
            <div class="card-body d-flex flex-column align-items-stretch">
              <h1 class="card-title pricing-card-title">
                $65 <small class="text-muted">/ mes</small>
              </h1>
              <div class="f-auto">
                <ul class="list-unstyled mt-3 mb-4">
                  <li>3 usuarios</li>
                  <li>5 Bodegas/Almacenes</li>
                  <li>POS</li>
                  <li>2500 facturas/Entradas en contabilidad</li>
                  <li>Declaración aduanal</li>
                  <li>Facturas de importacion</li>  
                  <li>Multimoneda</li>
                </ul>
              </div>
              <a
                class="w-100 btn btn-small btn-primary"
                :href="`mailto:${email}?subject=Plan Avanzado`"
                >{{ "Contáctanos" }}</a
              >
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { email } from "../../../utils/helpers"

export default {
  name: "Plans",
  components: {},
  data() {
    return {
      email: email
    };
  },
  created() {},
  mesunted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
