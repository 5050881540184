<template>
  <section
    class="pt-5 px-3 px-0 position-relative bg-dark text-white"
    id="contadores"
  >
    <div class="container position-relative">
      <div class="row pb-4 mt-4 mt-lg-5">
        <div class="col-12 col-md-6">
          <h1 class="">
            Aliado contador
          </h1>
          <p class="lead">Todas las cuentas de tus clientes en un solo lugar</p>
        </div>
        <div class="col-12 col-md-6">
          <p class="">
            Optimiza tu tiempo trabajando con Alliance, este te permitirá llevar
            en línea la contabilidad de cada uno de tus clientes teniendo acceso
            a la información que necesites cuando quieras y donde quieras. Te
            invitamos a formar parte de esta novedosa inteligencia contable y
            genera ingresos adicionales.
          </p>
          <a :href="`mailto:${email}?subject=Aliado contador`"
            >Si necesitas ayuda contáctanos <i class="ri-external-link-line"/></a>
        </div>
      </div>
    </div>
    <div class="container position-relative">
      <div  class="row justify-content-center align-items-center pt-4 pt-md-5">
        <div class="col-12">
          <img
            :src="require(`@/assets/cap/CapPrincipal.png`)"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import { email } from  from "././../utils/Helpers";
import { email } from "../../../utils/helpers"

export default {
  name: "Contadores",
  components: {},
  data() {
    return {
      email: email
    };
  }
};
</script>

<style lang="scss" scoped></style>
