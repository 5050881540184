<template>
  <section id="alliance" class="py-3 py-lg-5 position-relative bg-light">
    <div class="discover">
      <span class="text-uppercase pointer text-light"
        ><a class="text-white" href="#alliance">Explorar</a></span
      >
    </div>
    <div class="container py-3 py-lg-5">
      <div
        class="row no-gutters justify-content-center text-center px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
      >
        <div class="col-12 col-lg-7">
          <h1 class="text-dark title">
           Software administrativo y de control Gerencial
          </h1>
          <p class="lead mt-3">
            Alliance es una aplicación en la nube con más de 40 años de
            trayectoria en el desarrollo de soluciones empresariales, donde tu
            equipo de trabajo pueden ingresar desde cualquier parte del mundo.
          </p>
        </div>
      </div>

      <div class="row items justify-content-center py-4 position-relative d-none d-xl-flex">
        <div class="col-lg-4 position-relative">
          <img
            :src="require(`@/assets/svg/sync.svg`)"
            class="img-fluid"
            style="height: 200px;"
          />
          <div class="position-relative mt-3">
            <h2 class="number">1</h2>
            <p>
              Tus finanzas cuando quieras y donde quieras, consulta, vende y mantente informado
            </p>
          </div>
        </div>
        <div class="col-lg-4 position-relative">
          <img
            :src="require(`@/assets/svg/world.svg`)"
            class="img-fluid"
            style="height: 200px;"
          />
          <div class="position-relative mt-3">
            <h2 class="number">2</h2>
            <p>
              Toda la funcionalidad del sistema de facturación, administrativo y
              contabilidad más completo en la nube .
            </p>
          </div>
        </div>
        <div class="col-lg-4 position-relative">
          <img
            :src="require(`@/assets/svg/logo-main.svg`)"
            class="img-fluid"
            style="height: 200px;"
          />
          <div class="position-relative mt-3">
            <h2 class="number">3</h2>
            <p>
              Mantente conectado con tu equipo de trabajo, y con tus clientes.
              Envía información importante desde tu Alliance.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="position-relative bg-white" id="modulos">
    <div class="container py-5 position-relative">
      <div class="row align-items-center">
        <div class="col-12 text-center">
          <h1 class="title">Control administrativo y gerencial <br/> en todo momento</h1>
        </div>
      </div>
      <div class="row  items align-items-center p-0 pt-lg-3">
        <div class="col-12 text-center">
          <div class="d-flex flex-wrap justify-content-between modules">
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/gerencia.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Gerencia</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/contabilidad.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Contabilidad</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/tesoreria.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Tesorería</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/ventas.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Ventas</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/compras.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Compras</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/inventario.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Inventario</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/reportes.svg`)"
                  class="img-fluid"
                />
                <p class="small mt-2 my-0">Reportes</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                  :src="require(`@/assets/svg/LogisticaImportacion.svg`)"
                  class="img-fluid"
                />
                <p style="width: 80px;" class="small mt-2 my-0">Logistica de importacion</p>
              </div>
            </div>
            <div class="card border-0">
              <div class="card-body">
                <img
                :src="require(`@/assets/svg/puntoVenta.svg`)"
                  class="img-fluid"
                />
                <p style="width: 80px;" class="small mt-2 my-0">Punto de venta</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5 bg-light position-relative">
      <img
        class="logoInSwiper"
        :src="require(`@/assets/svg/logo-gradient.svg`)"
        alt="logo-gradient"
      />
      <swiper
        centeredSlides
        :slides-per-view="1"
        :space-between="100"
        :pagination="{ clickable: true }"
        mousewheel
        draggable
        autoplay
      >
        <swiper-slide>
          <div class="container">
            <div class="row align-items-center pb-4">
              <div class="col-12 col-lg-5 p-lg-5">
                <h1 class="text-dark">
                  Módulo de Compras
                </h1>
                <p class="text-muted mt-3">
                  Permitirá configurar el proceso operativo de acuerdo a la
                  estrategia de compras de su empresa; interactuando con el
                  subsistema de control de presupuestos. En este módulo podrá
                  generar órdenes de compra, recepciones y registrar las
                  facturas de compra (creando automáticamente la respectiva
                  Cuenta por pagar), este además genera automáticamente las
                  retenciones de impuesto establecidas por la Ley.
                </p>
                <img
                  :src="require(`@/assets/cap/CapCompras.png`)"
                  class="img-fluid shadow-lg d-block d-xl-none"
                />
              </div>
              <div class="col-12 col-lg-7 p-lg-5 d-none d-xl-block">
                <img
                  :src="require(`@/assets/cap/CapCompras.png`)"
                  class="img-fluid shadow-lg"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="container">
            <div class="row align-items-center pb-4">
              <div class="col-12 col-lg-5 p-lg-5">
                <h1 class="text-dark">
                  Módulo de Ventas y Control de Clientes
                </h1>
                <p class="text-muted mt-3">
                  Permitirá a las empresas configurar el proceso operativo
                  dentro del sistema de acuerdo a la estrategia de ventas de la
                  empresa, podrá cotizar, reservar inventario, emitir órdenes de
                  despacho, notas de entregas, devoluciones (incluso integradas
                  con otros módulos).
                </p>
                <img
                  :src="require(`@/assets/cap/CapVentas.png`)"
                  class="img-fluid shadow-lg d-block d-xl-none"
                />
              </div>
              <div class="col-12 col-lg-7 p-lg-5 d-none d-xl-block">
                <img
                  :src="require(`@/assets/cap/CapVentas.png`)"
                  class="img-fluid shadow-lg"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="container">
            <div class="row align-items-center pb-4">
              <div class="col-12 col-lg-5 p-lg-5">
                <h1 class="text-dark">
                  Módulo de Reportes
                </h1>
                <p class="text-muted mt-3">
                  Consulte toda la información que necesite desde cualquier
                  parte, en todo momento, expórtelos a Excel o envíelos por
                  correo, vaya un paso adelante.
                </p>
                <img
                  :src="require(`@/assets/cap/CapReportes.png`)"
                  class="img-fluid shadow-lg d-block d-xl-none"
                />
              </div>
              <div class="col-12 col-lg-7 p-lg-5 d-none d-xl-block">
                <img
                  :src="require(`@/assets/cap/CapReportes.png`)"
                  class="img-fluid shadow-lg"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="container">
            <div class="row align-items-center pb-4">
              <div class="col-12 col-lg-5 p-lg-5">
                <h1 class="text-dark">
                  Módulo de Inventario
                </h1>
                <p class="text-muted mt-3">
                  Con Alliance podrás llevar el control de múltiples bodegas
                  (almacenes) llevar el costo de sus artículos de la manera más
                  sencilla y eficiente, hacer ajustes de existencias, realizar
                  recepciones, conteos, ajustes de costos, costos aduanales,
                  efectuar ordenes de producción, todo integrado con los módulos
                  de compra, venta y contabilidad.
                </p>
                <img
                  :src="require(`@/assets/cap/CapInventario.png`)"
                  class="img-fluid shadow-lg d-block d-xl-none"
                />
              </div>
              <div class="col-12 col-lg-7 p-lg-5 d-none d-xl-block">
                <img
                  :src="require(`@/assets/cap/CapInventario.png`)"
                  class="img-fluid shadow-lg"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="container">
            <div class="row align-items-center pb-4">
              <div class="col-12 col-lg-5 p-lg-5">
                <h1 class="text-dark">
                  Punto de venta
                </h1>
                <p class="text-muted mt-3">
                  Con Alliance Pos tendras una poderosa herramienta para agilizar tus operaciones comerciales. Con una interfaz intuitiva y funcionalidades avanzadas, podrás gestionar tus ventas de manera eficiente y sin complicaciones. Desde la gestión de inventario hasta el procesamiento de pagos, 
                  nuestro punto de venta te brinda total control sobre tu negocio. Además, podrás generar informes detallados y obtener información en tiempo real para tomar decisiones estratégicas. 
                </p> 
                <img
                  :src="require(`@/assets/cap/CapPunto.png`)"
                  class="img-fluid shadow-lg d-block d-xl-none"
                />
              </div>
              <div class="col-12 col-lg-7 p-lg-5 d-none d-xl-block">
                <img
                  :src="require(`@/assets/cap/CapPunto.png`)"
                  class="img-fluid shadow-lg"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles2
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export default {
  name: "About",
  components: {
    Swiper,
    SwiperSlide
  }
};
</script>

<style lang="scss" scoped>
.discover {
  position: absolute;
  right: 50%;
  /* bottom: 0; */
  top: -6rem;
  transform: translateX(50%);
  z-index: 2;
  span {
    &:hover {
      opacity: 0.7;
      transition: all 0.1s ease-in-out;
    }
  }
  &::before {
    content: "";
    position: absolute;
    height: 100px;
    background: white;
    width: 3px;
    top: 2rem;
    z-index: 2;
    right: 50%;
  }
}
</style>
