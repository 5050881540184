<template>
  <div class="bar" />
  <div class="spacer" />
  <div id="cover">
    <div id="canva" />
    <div class="container mx-0">
      <div class="row no-gutters">
        <div class="col-12 col-md-5">
          <h1 ref="title" class="display-4 position-relative">
            Tus finanzas donde quieras y cuando quieras
          </h1>
          <p ref="subtitle" class="lead position-relative">
            Realiza consultas, facturas, revisa tus existencias y optimiza tu
            fuerza de venta.
          </p>
        </div>
      </div>
    </div>
    <img
      ref="logoGradient"
      class="logoGradient"
      :src="require(`@/assets/svg/logo-gradient.svg`)"
      alt="logo-gradient"
    />
    <img
      ref="logoOutline"
      class="logoOutline"
      :src="require(`@/assets/svg/logo-outline.svg`)"
      alt="logo-outline"
    />
  </div>
  <About />
  <Plans />
  <Contadores />
  <StayHome />
  <Footer />
  <div class="object" />
</template>

<script>
// Libs
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import * as ScrollMagic from "scrollmagic";

// import P5 from "p5";

// Views
import About from "./sections/About";
import Footer from "./sections/Footer";
import Plans from "./sections/Plans";
import Contadores from "./sections/Contadores";
import StayHome from "./sections/StayHome";

export default {
  name: "Home",
  components: {
    About,
    Footer,
    Plans,
    Contadores,
    StayHome
  },
  created() {
    // const sketch = s => {
    //   let w = window.innerWidth;
    //   let h = window.innerHeight;
    //   s.setup = () => {
    //     s.createCanvas(w, h, s.WEBGL);
    //     s.noStroke();
    //   };
    //   s.draw = () => {
    //     s.background(12, 28, 42);
    //     const dirY = (s.mouseY / s.height - 0.5) * 4;
    //     const dirX = (s.mouseX / s.width - 0.5) * 4;
    //     s.directionalLight(204, 204, 204, dirX, dirY, 2);
    //     s.push();
    //     s.ambientLight(245, 245, 245);
    //     s.ambientMaterial("#072146");
    //     s.rotateZ(s.frameCount * 0.0001);
    //     s.rotateX(s.frameCount * 0.0001);
    //     s.rotateY(s.frameCount * 0.0001);
    //     s.torus(w >= 768 ? 500 : 250, 10);
    //     s.pop();
    //     s.push();
    //     s.ambientLight(245, 245, 245);
    //     s.ambientMaterial("#072146");
    //     s.rotateZ(s.frameCount * 0.0001);
    //     s.rotateX(s.frameCount * 0.0001);
    //     s.rotateY(s.frameCount * 0.0001);
    //     s.torus(w >= 768 ? 700 : 400, 20);
    //     s.pop();
    //   };
    //   s.windowResized = () => {
    //     w = window.innerWidth;
    //     h = window.innerHeight;
    //     s.resizeCanvas(w, h);
    //   };
    // };
    // new P5(sketch, "canva");
  },
  mounted() {
    this.initAnimation();
  },
  methods: {
    initAnimation() {
      const title = this.$refs.title;
      const subtitle = this.$refs.subtitle;
      const logo1 = this.$refs.logoGradient;
      const logo2 = this.$refs.logoOutline;

      gsap
        .timeline()
        .fromTo(
          [title, subtitle, logo2, logo1],
          { duration: 0, y: -50, opacity: 0 },
          { delay: 1, duration: 1, y: 0, opacity: 1, stagger: 0.3 },
          "cover"
        );

      const sectionAbout = document.getElementById("alliance");
      this.$scrollMagic.addScene(
        new ScrollMagic.Scene({
          triggerElement: "#alliance",
          triggerHook: 1,
          offset: 100
        }).setTween(
          gsap.timeline().fromTo(
            [
              sectionAbout.querySelector(".title"),
              sectionAbout.querySelector(".lead")
            ],
            { opacity: 0, scale: 1.2, y: 300 },
            {
              opacity: 1,
              duration: 2,
              scale: 1,
              y: 0,
              stagger: 0.4,
              ease: "expo.out"
            }
          )
        )
      );

      this.$scrollMagic.addScene(
        new ScrollMagic.Scene({
          triggerElement: "#alliance",
          triggerHook: 1,
          offset: 100
        }).setTween(gsap.timeline().to([logo1], { opacity: 0 }))
      );

      this.$scrollMagic.addScene(
        new ScrollMagic.Scene({
          triggerElement: "#modulos",
          triggerHook: 1,
          offset: 100
        }).setTween(
          gsap.timeline().to([logo1, title, subtitle, logo2], { opacity: 0 })
        )
      );

      const sectionModules = document.getElementById("modulos");
      this.$scrollMagic.addScene(
        new ScrollMagic.Scene({
          triggerElement: "#modulos",
          triggerHook: 1,
          offset: 100
        }).setTween(
          gsap.timeline().fromTo(
            [
              sectionModules.querySelector(".title"),
              sectionModules.querySelector(".modules").children
            ],
            { opacity: 0, scale: 1.2, y: 300 },
            {
              opacity: 1,
              duration: 2,
              scale: 1,
              y: 0,
              stagger: 0.4,
              ease: "expo.out"
            }
          )
        )
      );

      const sectionContadores = document.getElementById("contadores");
      this.$scrollMagic.addScene(
        new ScrollMagic.Scene({
          triggerElement: "#contadores",
          triggerHook: 1,
          offset: 100
        }).setTween(
          gsap.timeline().fromTo(
            [sectionContadores, sectionContadores.querySelectorAll("h1")],
            { opacity: 0, scale: 1.2, y: 300 },
            {
              opacity: 1,
              duration: 2,
              scale: 1,
              y: 0,
              stagger: 0.4,
              ease: "expo.out"
            }
          )
        )
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
