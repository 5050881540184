<template>
  <section
    id="contacto"
    class="py-3 py-lg-5 position-relative bg-secondary text-white border-top"
  >
    <div class="container">
      <div
        class="row justify-content-center align-items-center py-3 pt-md-5 pb-md-4"
      >
        <div class="col-12">
          <h4 class="display-3 overflow-wrap-bw">
            <strong
              >Para consultas generales, envíe un correo electrónico a
              <a :href="`mailto:${email}`">{{ email }}</a></strong
            >
          </h4>
          <h4 class="lead mt-3 mt-md-5">
            Hable con un miembro de nuestro equipo de ventas <a class="" :href="`tel:${phone?.number}`">{{
              phone?.mask
            }}</a>
          </h4>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid bg-dark text-white">
    <footer class="container py-5">
      <div class="row justify-content-between">
        <div class="col-4 col-md-2">
          <img :src="require(`@/assets/svg/logo-full.svg`)" class="img-fluid" />
          <small class="d-block mb-3 text-muted">&copy; 2017-2023</small>
        </div>
        <div class="col-6 col-md small">
          <h5>Contáctanos</h5>
          <ul class="list-unstyled text-small">
            <li>
              <a :href="`tel:${phone?.number}`">{{
                phone?.mask
              }}</a>
            </li>
            <li>
              <a :href="`mailto:${email}`">{{ email }}</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Navegación</h5>
          <ul class="list-unstyled text-small text-ligth">
            <li
              v-for="item in mainMenu"
              :key="item.label"
            >
              <a class="" :href="item.anchor">{{ item.label }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-between mt-2">
        <div class="col-12">
          <span class="small text-muted"
            >All rights reserved - Alliance Inteligencia contable</span
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mainMenu } from "@/utils/Menu";
// import { email, phone } from "@/utils/Helpers";

import { email, phone } from "../../../utils/helpers"

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      mainMenu: [...mainMenu],
      phone: phone,
      email: email
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
