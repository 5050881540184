<template>
  <nav>
    <div class="container mb-4" id="nav">
      <div class="logo-float d-none d-xl-block">
        <router-link to="/"
          ><img src="./assets/svg/logo.svg" alt="logo"
        /></router-link>
      </div>
      <div class="d-flex ">
        <ul class="menu-lg">
          <li
            class="d-none d-xl-block"
            v-for="item in mainMenu"
            :key="item.label"
          >
            <a :href="item.anchor">{{ item.label }}</a>
          </li>
          <li :class="`${showMenuOverlay ? '' : 'd-block d-xl-none'}`">
            <transition name="no-mode-fade">
              <div class="bg-dark p-2 px-3">
                <i
                  class="h2 pointer"
                  :class="{
                    'ri-menu-line': !showMenuOverlay,
                    'ri-close-line': showMenuOverlay
                  }"
                  @click="menuOverlay().update()"
                ></i>
              </div>
            </transition>
          </li>
        </ul>
      </div>
      <div>
        <a
          :href="`http://app.alliance-cloud.com`"
          class="btn btn-link btn-sm mr-2 text-light d-none d-md-inline"
          >Crear una cuenta</a
        >
        <a href="http://app.alliance-cloud.com" class="btn btn-primary btn-sm">
          <i class="ri-login-box-fill small mr-1"></i> Iniciar sesión
        </a> 
      </div>
    </div>
  </nav>
  <nav class="overlay" ref="menuOverlay">
    <ul>
      <li
        v-for="item in mainMenu"
        :key="item.label"
        @click="
          () => {
            this.$data.showMenuOverlay = !this.$data.showMenuOverlay;
          }
        "
      >
        <a :href="item.anchor" class="h2">{{ item.label }}</a>
      </li>
      <li class="mt-3">
        <!-- <a
          class="text-muted"
          href="https://www.instagram.com/pucheosw.dev"
          rel="noopener"
        >
          <i class="ri-instagram-line"></i> instagram
        </a> -->
      </li>
      <li class="mt-3">
        <a
          class="text-muted mt-2"
          :href="`mailto:${email}?subject= Alliance`"
          target="_blank"
          rel="noopener"
        >
          Say Hola! <span class="emojii-wrap">👋</span>
        </a>
      </li>
    </ul>
  </nav>
  <router-view />
</template>

<script>
import { mainMenu }  from "./utils/Menu";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Helpers
// import { email } from "@/utils/Helpers";

import {email} from "./utils/helpers"

export default {
  name: "App",
  components: {},
  data() {
    return {
      email: email,
      showMenuOverlay: false,
      mainMenu: [...mainMenu]
    };
  },
  watch: {
    showMenuOverlay(newValue) {
      if (newValue) {
        this.menuOverlay().open();
      } else {
        this.menuOverlay().close();
      }
    }
  },
  mounted() {
    const preLoading = document.getElementById("preLoading");
    preLoading.classList += " leave";
  },
  methods: {
    menuOverlay() {
      return {
        update: () => {
          this.$data.showMenuOverlay = !this.$data.showMenuOverlay;
        },
        open: () => {
          const nav = this.$refs.menuOverlay;
          const ul = nav.querySelector("ul");
          nav.style.zIndex = 80;
          const tl = gsap.timeline();
          tl.to(this.$refs.menuOverlay, {
            duration: 0.1,
            width: window.innerWidth
          });
          tl.fromTo(
            ul.children,
            {
              opacity: 0,
              x: -100
            },
            {
              delay: 0.15,
              duration: 0.5,
              opacity: 1,
              x: 0,
              stagger: 0.2
            }
          );
        },
        close: () => {
          const nav = this.$refs.menuOverlay;
          const ul = nav.querySelector("ul");
          const tl = gsap.timeline();
          tl.to(
            ul.children,
            {
              duration: 0.5,
              opacity: 0,
              x: -100,
              stagger: 0.2
            },
            "root"
          );
          tl.to(
            this.$refs.menuOverlay,
            {
              duration: 0.5,
              width: 0,
              onComplete: () => {
                nav.style.zIndex = -2;
              }
            },
            "-=0.8"
          );
        }
      };
    }
  }
};
</script>

<style lang="scss">
@import "scss";
</style>
